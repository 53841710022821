<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="6">
        <h3>List of Institutions</h3>
      </b-col>
      <b-col cols="6">
        <div class="float-right mr-2">
          <b-form-input v-model="filter" placeholder="Search"></b-form-input>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
      <b-table :fields="tableFields" :busy="loading" :items="filteredInstitutions"
               :filter="filter" :filter-included-fields="['display_name']"
               outlined sticky-header="800px" striped small empty-text="No institutions to show">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(unit)="data">
          <b-icon font-scale="1" class="mr-2" v-if="data.value === 'dpt'" icon="tablet-landscape" title="DPT is active"></b-icon>
          <b-icon font-scale="1" class="mr-2" v-if="data.value === 'bloom'" icon="gender-female" title="Bloom is active"></b-icon>
        </template>

        <template #cell(actions)="data">
          <b-button v-if="data.item.clientID"
                    v-b-tooltip.hover
                    size="sm" variant="light" class="mr-2"
                    :to="'/onboarding/client/edit/'+data.item.clientID">
            <feather type="edit"></feather> Edit client
          </b-button>
          <b-button v-else
                    v-b-tooltip.hover
                    size="sm" variant="light" class="mr-2"
                    :to="'/onboarding/client/edit?institution='+data.item.id">
            <feather type="plus-square"></feather> Create client
          </b-button>
        </template>

      </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'Institutions.vue',
  data() {
    return {
      loadingInstitutions: false,
      loadingClients: false,
      clientList: [],
      institutionsList: [],
      searchBox: '',
      filter: '',
      tableFields: [
        { key: 'id', label: '#' },
        { key: 'display_name', label: 'Name' },
        { key: 'unit', label: 'Units' },
        { key: 'test', label: 'Test' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  methods: {
    fetchInstitutions() {
      this.loadingInstitutions = true;
      this.$store.dispatch('Core/fetchEnvInstitutions')
        .then(institutionsList => { this.institutionsList = institutionsList; })
        .catch(e => this.$noty.error('Failed to fetch institutions: ', e))
        .finally(() => { this.loadingInstitutions = false; });
    },
    fetchClients() {
      this.loadingClients = true;
      axios.get('v1/clients')
        .then(res => { this.clientList = res.data.payload; })
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch clients', e);
          console.error('Cannot fetch clients', e);
        })
        .finally(() => { this.loadingClients = false; });
    },
  },
  created() {
    this.fetchInstitutions();
    this.fetchClients();
  },
  computed: {
    loading() { return this.loadingClients || this.loadingInstitutions; },
    filteredInstitutions() {
      const institutions = [];
      this.institutionsList.forEach(institution => {
        if (!institution.is_new) {
          return;
        }
        const client = this.clientList.find(c => institution.unique_key === c.reference);
        institutions.push({
          id: institution.institutionID,
          display_name: institution.name,
          test: institution.is_test ? 'Yes' : 'No',
          unit: (institution.config && institution.config.is_bloom) ? 'bloom' : 'dpt',
          clientID: client ? client.id : null,
        });
      });
      return institutions;
    },
  },
};
</script>
